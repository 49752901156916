import { Button, Container, Header, Icon, Message, Segment, Tab } from "semantic-ui-react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { removeFirebasePrefix } from "../common/format";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { useState } from "react";
import { EmailFormSignUp } from "../components/EmailAuthForm";
import { PhoneFormLogin } from "../components/PhoneFormLogin";
import { loginWithAppleFirebase, loginWithGoogleFirebase, logout } from "../api/authentication";

function SignupSelectorTab({ onceLoginFinished }: { onceLoginFinished: () => void }) {
    const panes = [
        {
            menuItem: 'Email',
            render: () => (
                <Tab.Pane style={{ backgroundColor: 'transparent' }}>
                    <EmailFormSignUp
                        onceSignupFinished={onceLoginFinished}
                    />
                </Tab.Pane>
            )
        },
    ]

    return (
        <Tab
            style={{ backgroundColor: 'transparent' }}
            renderActiveOnly
            menu={{ fluid: true, tabular: true, widths: 2 }}
            panes={panes}
        />
    )
}


export function SignUpPage() {

    const navigate = useNavigate();
    const location = useLocation();

    const { authenticated } = useGeneralContext();
    const [error, setError] = useState<string>('');

    const handleLoginWithGoogle = () => {
        setError((prev) => '');
        loginWithGoogleFirebase()
            .then((userCredential) => { })
            .catch((error) => {
                setError((prev) => removeFirebasePrefix(error.code));
            });
    }

    const handleLoginWithApple = () => {
        setError((prev) => '');
        loginWithAppleFirebase()
            .then((userCredential) => { })
            .catch((error) => {
                setError((prev) => removeFirebasePrefix(error.code));
            });
    }

    const resumeNavigation = () => {
        if (location.state && location.state.from) {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    }

    const handleLogout = () => {
        logout();
    }

    if (error) {
        return (
            <Message negative>
                <Message.Header>We're sorry ! Something wrong</Message.Header>
                <p>{error}</p>
            </Message>
        )
    }

    return (
        <ResponsiveContainer>
            <Header as='h3' style={{ fontSize: '2em', backgroundColor: 'transparent' }}>
                Sign Up
            </Header>
            <p style={{ fontSize: '1.2 em' }}>{(authenticated) ? "You are authenticate" : "You need to have an account to be able to access the rest of this web app. To create an account please pass by mobile app."}</p>
            {!authenticated && <SignupSelectorTab onceLoginFinished={resumeNavigation} />}
            <Segment basic style={{ backgroundColor: 'transparent' }}>
                <Container className="mt-4">
                    {!authenticated ?
                        <>
                            <Container style={{ marginTop: 10, backgroundColor: 'transparent' }}>
                                <Button
                                    onClick={handleLoginWithGoogle}
                                    style={{ width: 200, height: 50 }}
                                    color="google plus"
                                >
                                    <Icon name="google" /> Sign Up with Google
                                </Button>
                            </Container>
                            <Container style={{ marginTop: 10, backgroundColor: 'transparent' }}>
                                <Button
                                    onClick={handleLoginWithApple}
                                    style={{ width: 200, height: 50 }}
                                    color="black"
                                >
                                    <Icon name="apple" /> Sign Up with Apple
                                </Button>
                            </Container>
                        </> : null}
                    {authenticated ?
                        <Container style={{ marginTop: 10, backgroundColor: 'transparent' }}>
                            <Button onClick={handleLogout} style={{ width: 200, height: 50 }} color="red">Logout</Button>
                        </Container> : null}
                </Container>
                {!authenticated ?
                    <Container style={{ marginTop: 20 }}>
                        <Link to="/login" state={location.state}>Already Have An Account ?</Link>
                    </Container> : null}
            </Segment>
        </ResponsiveContainer>
    )
}