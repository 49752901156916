import { useCallback, useEffect, useRef, useState } from "react";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { getAffiliatedBusinessToUser, getAffiliatedUnregisteredBusiness, getBusinessProfileInfo } from "../api/firebase";
import { Card, Container } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { BusinessCreationData } from "../models/models";
import * as Sentry from "@sentry/react";

interface AffiliatedBusiness {
    image: string;
    header: string;
    description: string;
    meta: string;
    store_id: string;
}

export default function AffiliatedBusinessesCardGroup() {
    const { currentUser } = useGeneralContext();
    const [affiliatedRegisteredBusinesses, setAffiliatedRegisteredBusinesses] = useState<AffiliatedBusiness[]>([]);
    const [affiliatedUnregisteredBusinesses, setAffiliatedUnregisteredBusinesses] = useState<BusinessCreationData[]>([]);
    const mounted = useRef(false);
    const navigate = useNavigate();

    const fetchAffiliatedBusiness = useCallback(async () => {
        if (!currentUser) {
            return;
        }
        const data = await getAffiliatedBusinessToUser(currentUser.uid);
        const transformed = Promise.all(data.map(async (entry) => {
            const businessInfo = await getBusinessProfileInfo(entry.store_id)
            if (!businessInfo) return undefined
            return {
                image: businessInfo.image,
                header: businessInfo.name,
                description: businessInfo.small_description,
                meta: entry.role,
                store_id: entry.store_id
            }
        })
        );
        const filtered = (await transformed).filter((entry) => entry !== undefined) as AffiliatedBusiness[];
        setAffiliatedRegisteredBusinesses(filtered)
    }, [currentUser, setAffiliatedRegisteredBusinesses]);

    const fetchUnregisteredAffiliatedBusiness = useCallback(async () => {
        if (!currentUser) {
            return;
        }
        await getAffiliatedUnregisteredBusiness()
            .then((resp) => {
                if (resp.status === "success") {
                    setAffiliatedUnregisteredBusinesses(resp.data);
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    }, [currentUser]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        setAffiliatedRegisteredBusinesses((prv) => []);
        fetchAffiliatedBusiness();
        fetchUnregisteredAffiliatedBusiness();
    }, [currentUser, fetchAffiliatedBusiness, fetchUnregisteredAffiliatedBusiness]);

    
    return (
        <Container>
            {affiliatedRegisteredBusinesses.length > 0 &&
                <>
                    {affiliatedRegisteredBusinesses.map((entry) =>
                        <Card
                            key={entry.store_id + "card"}
                            onClick={() => navigate(`/business/${entry.store_id}`)}
                            image={entry.image}
                            header={entry.header}
                            meta={entry.meta}
                            description={entry.description}
                        />
                    )}
                </>
            }
            {affiliatedRegisteredBusinesses.length === 0 && affiliatedUnregisteredBusinesses.length === 0 &&
                <p>You are not affiliated to a registered business. You can apply for a new business</p>}
            {affiliatedUnregisteredBusinesses.length > 0 &&
                <>
                    {affiliatedUnregisteredBusinesses.map((entry, index) =>
                        <Card
                            key={"unregistered" + index}
                            header={entry.store_name}
                            meta={"Under review"}
                            description={"The request is being processed by our team. You will be notified when the business is registered."}
                        />
                    )}
                </>
            }
        </Container>
    )
}