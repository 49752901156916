export function removeFirebasePrefix(errorMsg: string): string {
    const bracketIndex = errorMsg.indexOf(']');
    if (bracketIndex === -1) {
        return errorMsg;
    }
    return errorMsg.slice(bracketIndex + 1).trim();
}

export function getTimeStringFromFirebaseTimestamp(timestampObject: { seconds: number, nanoseconds: number }): string {
    const date = new Date(timestampObject.seconds * 1000);
    return date.toLocaleTimeString();
}