import {
    getAuth, signInWithPopup, GoogleAuthProvider,
    setPersistence, browserLocalPersistence, signOut, onAuthStateChanged,
    signInWithEmailAndPassword, signInWithPhoneNumber, OAuthProvider,
    createUserWithEmailAndPassword,
} from "firebase/auth";

import { auth } from "./firebase";

const googleProvider = new GoogleAuthProvider();

(async () => {
    await setPersistence(auth, browserLocalPersistence);
})();

export async function onAuthStateChangedWrapper() {
    return onAuthStateChanged(auth, (user) => user);
}

export async function loginWithGoogleFirebase() {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => signInWithPopup(auth, googleProvider))
        .catch((error) => console.log(error));
}

export async function loginWithAppleFirebase() {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    const auth = getAuth();
    signInWithPopup(auth, provider);
}

export async function logout() {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => signOut(auth));
}

export async function logInWithEmailAndPassword(email: string, password: string) {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password));
}

export async function signUpWithEmailAndPassword(email: string, password: string) {
    return setPersistence(auth, browserLocalPersistence)
        .then(() => createUserWithEmailAndPassword(auth, email, password));
}

export async function loginWithPhoneNumber(phoneNumber: string, recaptchaVerifier: any) {
    return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
}