import { initializeApp } from 'firebase/app';

import {
    getFirestore, collection, getDocs,
    setDoc, doc, limit, query,
    orderBy, startAfter, where, addDoc, deleteDoc
} from 'firebase/firestore';

import {
    getAuth, setPersistence, browserSessionPersistence
} from "firebase/auth";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import {
    BusinessCreationData,
    BusinessCreationDataUpdate, BusinessCreationRequest, BusinessToUserMap, ExistingBusinessProfileData, ExistingProductData,
    UpdatingPostData, UploadingPostData
} from '../models/models';
import { getFunctions, httpsCallable } from "firebase/functions";
import * as Sentry from "@sentry/react";

const firebaseConfig = {
    apiKey: process.env["REACT_APP_FIREBASE_apiKey"],
    authDomain: process.env["REACT_APP_FIREBASE_authDomain"],
    projectId: process.env["REACT_APP_FIREBASE_projectId"],
    storageBucket: process.env["REACT_APP_FIREBASE_storageBucket"],
    messagingSenderId: process.env["REACT_APP_FIREBASE_messagingSenderId"],
    appId: process.env["REACT_APP_FIREBASE_appId"],
    measurementId: process.env["REACT_APP_FIREBASE_measurementId"]
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const storage = getStorage(app);
export const auth = getAuth(app);
auth.useDeviceLanguage();
const functions = getFunctions(app);

const businessesRegisteredCollectionRef = collection(db, "storesRegistered");
const postsCollectionRef = collection(db, "posts");
const businessesToUsersMappingCollectionRef = collection(db, "storesToUsersMapping");
const businessesProfileDataCollectionRef = collection(db, "storesProfileData");
const businessesProductsCollectionRef = collection(db, "storesProducts");

(async () => {
    await setPersistence(auth, browserSessionPersistence);
})();


// ************************** Storage Related **************************

export async function uploadFileToStorage(file: File, fullfileName: string) {
    const uploadRef = await uploadBytes(ref(storage, `${fullfileName}`), file);
    return getDownloadURL(uploadRef.ref);
}

// ************************** Business Related **************************

export interface CloudFunctionsResponse {
    status: "success" | "error";
    message: string;
    code: number;
}

export interface BusinessRegisterRequestResponse extends CloudFunctionsResponse {
    data?: { errors: string[], isValid: boolean };
}

export async function requestBusinessSubmission(data: BusinessCreationRequest): Promise<BusinessRegisterRequestResponse> {
    const fun = httpsCallable(functions, "submitRequestToRegisterBusiness");
    return (await fun(data)).data as BusinessRegisterRequestResponse;
}

export interface UnregisteredAffiliatedBusinessResponse extends CloudFunctionsResponse {
    data: BusinessCreationData[];
}

export async function getAffiliatedUnregisteredBusiness(): Promise<UnregisteredAffiliatedBusinessResponse> {
    const fun = httpsCallable(functions, "fetchUnRegisteredAffiliatedBusinesses");
    return (await fun()).data as UnregisteredAffiliatedBusinessResponse;
}

export async function getAffiliatedBusinessToUser(uid: string): Promise<BusinessToUserMap[]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesToUsersMappingCollectionRef.path),
            where("uid", "==", uid)
        )
    );
    return querySnapshot.docs.map((val) => val.data()) as BusinessToUserMap[];
}

export async function getBusinessProfileInfo(store_id: string): Promise<ExistingBusinessProfileData | null> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesProfileDataCollectionRef.path),
            where("store_id", "==", store_id),
            limit(1)
        )
    );
    if (querySnapshot.empty) {
        return null;
    }
    return { ...querySnapshot.docs[0].data(), _reference: querySnapshot.docs[0].id } as ExistingBusinessProfileData;
}

export async function updateBusinessProfileInfo(body: ExistingBusinessProfileData) {
    if (!body._reference) {
        throw new Error("Can't update business information without _reference.");
    }
    const toSet = { ...body };
    delete toSet._reference;
    await setDoc(
        doc(db, `${businessesProfileDataCollectionRef.path}/${body._reference}`),
        toSet,
        { merge: true }
    );
}

export async function getRegisteredBusiness(store_id: string): Promise<BusinessCreationDataUpdate | null> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesRegisteredCollectionRef.path),
            where("store_id", "==", store_id),
            limit(1)
        )
    );
    if (querySnapshot.empty) {
        return null;
    }
    return querySnapshot.docs[0].data() as BusinessCreationDataUpdate;
}

// ************************** Posts Related **************************

export async function infiniteBusinessPostsStart(store_id: string): Promise<[UpdatingPostData[], any]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, postsCollectionRef.path),
            where("store_id", "==", store_id),
            orderBy("posted", "desc"),
            limit(10)
        )
    );
    return [
        querySnapshot.docs.map((documentSnapshot) => Object.assign({ _reference: documentSnapshot.id }, documentSnapshot.data())) as UpdatingPostData[],
        querySnapshot.docs[querySnapshot.docs.length - 1]
    ];
}

export async function infiniteBusinessPostsNextBatch(store_id: string, objectToStartFrom: any): Promise<[UpdatingPostData[], any]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, postsCollectionRef.path),
            where("store_id", "==", store_id),
            orderBy("posted", "desc"),
            startAfter(objectToStartFrom),
            limit(10)
        )
    );

    return [
        querySnapshot.docs.map((documentSnapshot) => Object.assign({ _reference: documentSnapshot.id }, documentSnapshot.data())) as UpdatingPostData[],
        querySnapshot.docs[querySnapshot.docs.length - 1]
    ];
}

export async function uploadAPost(post: UploadingPostData) {
    const fun = httpsCallable(functions, "uploadBusinessPost");
    const response = (await fun(post)).data as CloudFunctionsResponse;
    if (response.status === "error") {
        Sentry.captureException(new Error(`Error uploading post: ${response.message}`));
        throw new Error(response.message);
    }
}

export async function updatePost(post: UpdatingPostData) {
    const data = { ...post };
    delete data._reference;
    const fun = httpsCallable(functions, "updateBusinessPost");
    const response = (await fun(data)).data as CloudFunctionsResponse;
    if (response.status === "error") {
        Sentry.captureException(new Error(`Error updating post: ${response.message}`));
        throw new Error(response.message);
    }
}

export async function deletePost(post: UpdatingPostData, store_id: string) {
    if (!post._reference) {
        throw new Error("Trying to delete a post without reference");
    }
    const fun = httpsCallable(functions, "businessDeletePost");
    const response = (await fun({ post_id: post.id, store_id })).data as CloudFunctionsResponse;
    if (response.status === "error") {
        Sentry.captureException(new Error(`Error deleting post: ${response.message}`));
        throw new Error(response.message);
    }
}

// ************************** Products Related **************************

export async function infiniteBusinessProductsStart(store_id: string): Promise<[ExistingProductData[], any]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesProductsCollectionRef.path),
            where("store_id", "==", store_id),
            orderBy("product_name", "asc"),
            limit(10)
        )
    );
    return [
        querySnapshot.docs.map((documentSnapshot) => Object.assign({ _reference: documentSnapshot.id }, documentSnapshot.data())) as ExistingProductData[],
        querySnapshot.docs[querySnapshot.docs.length - 1]
    ];
}

export async function infiniteBusinessProductsNextBatch(store_id: string, objectToStartFrom: any): Promise<[ExistingProductData[], any]> {
    const querySnapshot = await getDocs(
        query(
            collection(db, businessesProductsCollectionRef.path),
            where("store_id", "==", store_id),
            orderBy("product_name", "asc"),
            startAfter(objectToStartFrom),
            limit(10)
        )
    );

    return [
        querySnapshot.docs.map((documentSnapshot) => Object.assign({ _reference: documentSnapshot.id }, documentSnapshot.data())) as ExistingProductData[],
        querySnapshot.docs[querySnapshot.docs.length - 1]
    ];
}

export async function updateProduct(prod: ExistingProductData) {
    if (!prod._reference) {
        throw new Error("Trying to update a product without reference");
    }
    const data = { ...prod };
    delete data._reference;
    return setDoc(
        doc(db, `${businessesProductsCollectionRef.path}/${prod._reference}`),
        data,
        { merge: true }
    )
}

export async function uploadProduct(prod: ExistingProductData) {
    const querySnapshot = await getDocs(
        query(
            postsCollectionRef,
            where("id", "==", prod.id),
            limit(1)
        )
    );
    if (!querySnapshot.empty) {
        throw new Error("Post ID already exist")
    }
    return addDoc(businessesProductsCollectionRef, prod);
}

export async function deleteProduct(prod: ExistingProductData) {
    if (!prod._reference) {
        throw new Error("Trying to delete a product without reference");
    }
    return deleteDoc(
        doc(db, `${businessesProductsCollectionRef.path}/${prod._reference}`),
    )
}

export async function requestAdminAccessToStore(store_id: string) {
    const fun = httpsCallable(functions, "requestAdminAccessToStore");
    const response = (await fun({ store_id })).data as CloudFunctionsResponse;
    if (response.status === "error") {
        Sentry.captureException(new Error(`Error requesting admin access to store: ${response.message}`));
        throw new Error(response.message);
    }
}