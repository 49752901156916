import { ReactNode, createContext, useContext, useState } from "react";
import { ExistingProductData, PostMediaContent, ProductData, 
    ProductDataDefault } from "../../models/models";
import { deleteProduct, updateProduct, uploadFileToStorage, 
    uploadProduct } from "../../api/firebase";
import { useBusinessContext } from "./BusinessContext";
import uuid from "react-uuid";
import * as Sentry from "@sentry/react";

export enum ProductContextStatus{
    New, 
    Updating
}
interface ProductContextType{
    addFiles: (files: File[]) => void;
    removeFiles: (files: File[]) => void;
    clearState: () => void;
    imagesBeingUploaded: File[];
    imagesUploaded: PostMediaContent[] | undefined;
    currentProduct: ExistingProductData | ProductData;
    status: ProductContextStatus;
    setStatus: (status: ProductContextStatus) => void;
    setProduct: (product: ExistingProductData) => void;
    setName: (text: string) => void;
    setDescription: (textArea: string) => void;
    setPrice: (price: string) => void;
    setType: (price: string) => void;
    setCurrency: (currency: string) => void;
    setCategories: (categories: string[]) => void;
    updateProduct: () => Promise<void>;
    uploadProduct: () => Promise<void>;
    removeProduct: () => Promise<void>;
}

const ProductContext = createContext<ProductContextType>({
    addFiles: (files) => {},
    removeFiles: (files) => {},
    clearState: () => {},
    imagesBeingUploaded: [],
    imagesUploaded: undefined,
    currentProduct: ProductDataDefault,
    status: ProductContextStatus.New,
    setStatus: (status: ProductContextStatus) => {},
    setProduct: (product: ExistingProductData) => {},
    setName: (text: string) => {},
    setDescription: (textArea: string) => {},
    setPrice: (price: string) => {},
    setType: (price: string) => {},
    setCurrency: (currency: string) => {}, 
    setCategories: (categories: string[]) => {},
    updateProduct: async () => {},
    uploadProduct: async () => {}, 
    removeProduct: async () => {}
})

export const useProductContext = () => useContext(ProductContext);

interface ProductContextProps{
    children?: ReactNode;
}

export default function ProductContextProvider({children}: ProductContextProps){
    const {currentBusiness} = useBusinessContext();
    const [status, setStatus] = useState<ProductContextStatus>(ProductContextStatus.New);
    const [filesBeingUploaded, setFilesBeingUploaded]   = useState<File[]>([]);
    const [filesUploaded, setFilesUploaded]             = useState<PostMediaContent[] | undefined>(undefined);
    const [currentProduct, setCurrentProduct] = useState<ProductData | ExistingProductData>({...ProductDataDefault});
    
    const addImages = (files: File[]) => {
        setFilesBeingUploaded((prev) => [ ...prev, ...files])
    }

    const removeImages = (files: File[]) => {
        setFilesBeingUploaded((prev) => {
            return prev.filter((file) => !files.includes(file))
        })
    }

    const onSetProduct = (prod: ProductData | ExistingProductData) => {
        setCurrentProduct(prod); 
        setFilesUploaded(prod.media);
    } 

    const onClearState = () => {
        setStatus(ProductContextStatus.New);
        setFilesBeingUploaded([]);
        setFilesUploaded(undefined);
        setCurrentProduct(ProductDataDefault);
    }

    const validityChecks = (prod: ProductData) => {
        if (!["product", "service"].includes(prod.type)){
            throw new Error("The type is invalid.");
        }
        if (prod.product_name.length === 0){
            throw new Error("The product name can't be empty");
        }
        if (!currentBusiness){
            throw new Error("Can't know which business is being modified");
        }
        if (!prod.store_id){
            throw new Error("The product doesn't have a store id");
        }
    }

    const onUpdateProduct = async () => {
        validityChecks(currentProduct);
        await updateProduct(currentProduct);
    }

    const onUploadProduct = async () => {
        try {
            const toUpload: ProductData = {...currentProduct, id: uuid(), store_id: currentBusiness?.store_id || ""};
            validityChecks(toUpload);
            let media;
            if (filesBeingUploaded.length > 0){
                media = await Promise.all(filesBeingUploaded.map(async (file) => {
                    return { source: await uploadFileToStorage(file, `postsMedia/${toUpload.id}/${file.name}`), type: file.type.split("/")[0]}
                }));
            }
            if (media){
                toUpload.media = media;
            } else {
                delete toUpload.media;
            }
            await uploadProduct(toUpload);
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    const onDeleteProduct = async () => {
        validityChecks(currentProduct);
        if (status !== ProductContextStatus.Updating){
            throw new Error("Status is incoherent please report to us the error with a description.")
        }
        await deleteProduct(currentProduct);
    }

    const value = {
        addFiles: addImages,
        removeFiles: removeImages,
        setProduct: onSetProduct, 
        status: status,
        imagesBeingUploaded: filesBeingUploaded, 
        imagesUploaded: filesUploaded,
        currentProduct: currentProduct, 
        setStatus: setStatus,
        clearState: onClearState,
        setName: (text: string) => setCurrentProduct((prev) => ({...prev, product_name: text})),
        setDescription: (descr: string) => setCurrentProduct((prev) => ({...prev, description: descr})),
        setPrice: (price: string) => setCurrentProduct((prev) => ({...prev, price: { amount: parseFloat(price), currency: prev.price.currency}})),
        setCurrency: (currency: string) => setCurrentProduct((prev) => ({...prev, price: { amount: prev.price.amount, currency: currency}})), 
        setType: (type: string) => setCurrentProduct((prev) => ({...prev, type: type})),
        setCategories: (categories: string[]) => setCurrentProduct((prev) => ({...prev, categories: categories})),
        uploadProduct: onUploadProduct, 
        updateProduct: onUpdateProduct, 
        removeProduct: onDeleteProduct
    }
    return (
        <ProductContext.Provider value={value}>
            {children}
        </ProductContext.Provider>
    )
}