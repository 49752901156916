import { useEffect, useState } from "react";
import { Button, Form, Grid, Input, Message, Segment } from "semantic-ui-react";
import { removeFirebasePrefix } from "../common/format";
import { logInWithEmailAndPassword, signUpWithEmailAndPassword } from "../api/authentication";

export function EmailFormLogin({onceLoginFinished}: {onceLoginFinished: () => void}){
    const [email, setEmail] = useState<string>('');
    const [passcode, setPassCode] = useState<string>('');
    const [error, setError] = useState<string>('');

    const onLoginWithMail  = async () => {
        setError((prev) => '');
        if (email.length === 0){
            return setError("Email is required");
        } else if (passcode.length === 0){
            return setError("Passcode is required");
        }
        logInWithEmailAndPassword(email, passcode)
            .then((userCredential) => {onceLoginFinished();})
            .catch((error) => {
                setError((prev) => removeFirebasePrefix(error.code));
            });
    }

    return (
        <Segment basic>
            <Grid centered verticalAlign='middle'>
                {error && <Grid.Column width={12}>
                    <Message negative>{error}</Message>
                </Grid.Column>}
                <Grid.Column width={12}>
                    <Form>
                        <Form.Field>
                            <label>Email</label>
                            <Input 
                                placeholder='Email' 
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Password</label>
                            <Input 
                                type='password' 
                                placeholder='Password' 
                                onChange={(event) => setPassCode(event.target.value)} 
                            />
                        </Form.Field>
                        <Button
                            type='submit' 
                            primary 
                            onClick={onLoginWithMail}
                        >Submit</Button>
                    </Form>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export function EmailFormSignUp({onceSignupFinished}:{onceSignupFinished: () => void}){
    const [email, setEmail] = useState<string>('');
    const [passcode, setPassCode] = useState<string>('');
    const [confirmPasscode, setConfirmPasscode] = useState<string>('');
    const [error, setError] = useState<string>('');

    const onSignupWithMail  = async () => {
        setError((prev) => '');
        if (email.length === 0){
            return setError("Email is required");
        } else if (passcode.length === 0){
            return setError("Passcode is required");
        }
        signUpWithEmailAndPassword(email, passcode)
            .then((userCredential) => {onceSignupFinished();})
            .catch((error) => {
                setError((prev) => removeFirebasePrefix(error.code));
            });
    }

    useEffect(() => {
        if (confirmPasscode.length > 0 && confirmPasscode.length > 0 && confirmPasscode !== passcode) {
            setError("Passcode doesn't match.")
        } else if (error === "Passcode doesn't match."){
            setError(() => "");
        }
    }, [error, setError, confirmPasscode, passcode])

    return (
        <Segment basic>
            <Grid centered verticalAlign='middle'>
                {error && <Grid.Column width={12}>
                    <Message negative>{error}</Message>
                </Grid.Column>}
                <Grid.Column width={12}>
                    <Form>
                        <Form.Field>
                            <label>Email</label>
                            <Input 
                                placeholder='Email' 
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Password</label>
                            <Input 
                                type='password' 
                                placeholder='Password' 
                                onChange={(event) => setPassCode(event.target.value)} 
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Confirm Password</label>
                            <Input 
                                type='password' 
                                placeholder='Confirm Password' 
                                onChange={(event) => setConfirmPasscode(event.target.value)} 
                            />
                        </Form.Field>
                        <Button
                            type='submit' 
                            primary 
                            onClick={onSignupWithMail}
                        >Submit</Button>
                    </Form>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}