import { useEffect, useState } from "react";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import { Dimmer, Message, Segment } from "semantic-ui-react";
import { requestAdminAccessToStore } from "../api/firebase";
import { Link } from "react-router-dom";

export function MigrationToEmail() {
    const queryParameters = new URLSearchParams(window.location.search);
    const storeId = queryParameters.get("storeId");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!storeId) {
            setError("Invalid URL. Please contact contact@chaguoo.com to resolve this issue.");
            return;
        }
        const abortController = new AbortController();
        const signal = abortController.signal;
        const runRequest = async () => {
            try {
                setLoading(() => true);
                await requestAdminAccessToStore(storeId);
                setError(() => "");
            } catch (error) {
                if (signal.aborted) return
                setError(() => (error as Error).message);
            } finally {
                if (signal.aborted) return
                setLoading(() => false);
            }
        }
        runRequest();
        return () => {
            abortController.abort();
        }
    }, [storeId]);

    return (
        <ResponsiveContainer>
            <div>
                <h1>Migration to Email</h1>
                <p>
                    We are migrating our users to email based login. From now on, you will need to use your email address to access your business account.
                </p>
            </div>
            {error ?
                (
                    <Message negative floating>
                        <Message.Header>An error occurred</Message.Header>
                        {error}
                    </Message>
                ) : null}
            {loading ?
                (
                    <Segment style={{ height: '70vh' }}>
                        <Dimmer active={loading}>
                            <div>Loading...</div>
                        </Dimmer>
                    </Segment>
                ) : null}
            {!loading && !error ?
                (
                    <Message positive floating>
                        <Message.Header>Success</Message.Header>
                        Your request has been approved. You can now access your business account using your email address. 
                        <Link to="/">Go to Home</Link>
                    </Message>
                ) : null}
        </ResponsiveContainer>
    )
}