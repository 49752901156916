import { Container, Menu, Dropdown } from 'semantic-ui-react';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { appName } from '../common/constant';

interface DefaultHeaderProps{
    mobile?: boolean;
}

export function DefaultHeader({mobile} : DefaultHeaderProps) {
    const { authenticated, currentUser } = useGeneralContext();

    if (mobile) {
        return (
            <>
                <Menu.Item as='a' href='/'>My Businesses</Menu.Item>
                <Menu.Item as='a' href='/register-business'>Register Business</Menu.Item>
                {authenticated && <Menu.Item as='a' href='/login'>Log Out</Menu.Item>}
                {!authenticated && <Menu.Item as='a' href='/login'>Authentication</Menu.Item>}

            </>
        )
    }

    return (
        <Menu inverted style={{borderRadius: 0}} size='large'>
            <Container>
                <Menu.Item as='a' header href="/">
                    {appName}
                </Menu.Item>    
                <Menu.Menu position='right'>
                    <Menu.Item as='a' href='/'>My Businesses</Menu.Item>
                    <Menu.Item as='a' href='/register-business'>Register Business</Menu.Item>
                    {authenticated ? (
                    <Dropdown item placeholder={currentUser?.displayName || "User"}>
                        <Dropdown.Menu style={{backgroundColor: 'white'}}>
                            <Dropdown.Item as='a' href='/login' text="Log Out"/>
                        </Dropdown.Menu>
                    </Dropdown>
                    ) : (
                    <Menu.Item as='a' href='/login'>Authentication</Menu.Item>
                    )}
                </Menu.Menu>
            </Container>
        </Menu>
    );
}
