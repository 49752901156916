import { Button, Container, Form, Grid, Input, InputOnChangeData, Loader, Message, TextArea } from "semantic-ui-react";
import { AboutContextState, useAboutContext } from "../../storage/context/AboutContext";
import MediaSlider from "../../components/MediaSlider";
import { IMAGE_MAX_SIZE } from "../../common/file";
import { useState } from "react";

export default function AboutSubPage() {
    const { currentObject, setAbout, setBusinessName, saveChanges, uploadedProfilePicture,
        setShortDescription, status, discardChanges, addProfilePicture, loading } = useAboutContext();
    const [errorMesssage, setErrorMessage] = useState("");

    if (!currentObject) {
        return (<></>);
    }

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (e.target.files) {
            if (e.target.files[0].size > IMAGE_MAX_SIZE) {
                alert(`File ${e.target.files[0].name} surpasses the maximum size.`);
            } else {
                addProfilePicture(e.target.files[0]);
            }
        }
    }

    const onSaveChanges = () => {
        saveChanges()
            .then(() => {
            })
            .catch((err) => {
                setErrorMessage((err as Error).message);
            })
    }

    if (loading) {
        return (
            <Loader content='Loading' />
        );
    }


    return (
        <Container fluid>
            <Container style={{ marginTop: 10, marginBottom: 10 }}>
                <h2>Business Profile</h2>
                <p>On the page you will be able to modify the business's information, etc.</p>
            </Container>
            <Container>
                <Form>
                    <Form.Field>
                        <label>Name</label>
                        <input
                            placeholder='Business name'
                            type="text"
                            value={currentObject.name}
                            onChange={(e) => { setBusinessName(e.target.value); }}
                            maxLength={60}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Short Description</label>
                        <input
                            placeholder='Write a short description about your business ...'
                            type="text"
                            value={currentObject.small_description}
                            onChange={(e) => { setShortDescription(e.target.value); }}
                            maxLength={100}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>About</label>
                        <TextArea
                            placeholder='Write a full description about your business ...'
                            value={currentObject.about || ""}
                            onChange={(e) => { setAbout(e.target.value); }}
                        />
                    </Form.Field>

                    <Grid.Column>
                        <Container style={{width: 200, height: 200}}>
                            {uploadedProfilePicture ?
                                <MediaSlider cover hideDotGroup localFiles={[uploadedProfilePicture]} /> :
                                <MediaSlider cover hideDotGroup remoteFiles={[{ type: "image", source: currentObject.image }]} />
                            }
                        </Container>
                        <Container>
                            <Form.Field>
                                <label>Profile Image</label>
                                <Input
                                    type="file"
                                    capture="environment"
                                    accept="image/*"
                                    onChange={handleFileSelect}
                                />
                            </Form.Field>
                        </Container>
                    </Grid.Column>
                    {errorMesssage &&
                        <Message negative>
                            <Message.Header>There was some errors with your submission</Message.Header>
                            <p>{errorMesssage}</p>
                        </Message>}
                    <Grid.Column style={{ marginTop: 20 }}>
                        {status === AboutContextState.Updating && <Button
                            content="Discard"
                            icon="save"
                            secondary
                            type="submit"
                            onClick={discardChanges}
                            labelPosition="left"
                        />}
                        {status === AboutContextState.Updating && <Button
                            content="Save Changes"
                            icon="save"
                            primary
                            type="submit"
                            onClick={onSaveChanges}
                            labelPosition="left"
                        />}
                    </Grid.Column>
                </Form>
            </Container>
        </Container>
    )
}