import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-css/semantic.min.css'
import GeneralContextProvider from './storage/context/GeneralContext';
import BusinessContextProvider from './storage/context/BusinessContext';
import React from 'react';
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://e26a1e39510ac606d4b5d3d02f9908d0@o4507056602873856.ingest.us.sentry.io/4507056605691904",
    integrations: [],
    enabled: process.env.NODE_ENV !== 'development',
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <GeneralContextProvider>
                <BusinessContextProvider>
                    <App />
                </BusinessContextProvider>
            </GeneralContextProvider>
        </Sentry.ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
