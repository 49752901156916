import PostsSubPage from './PostsSubPage';
import ProductsSubPage from './ProductsSubPage';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBusinessContext } from '../../storage/context/BusinessContext';
import {
    Container, Dropdown, 
    Grid, Icon, Menu, Segment,
    Sidebar, Tab 
} from 'semantic-ui-react';
import PostContextProvider from '../../storage/context/PostsContext';
import ProductContextProvider from '../../storage/context/ProductContext';
import BillingSubPage from './BillingSubPage';
import AboutSubPage from './AboutSubPage';
import AboutContextProvider from '../../storage/context/AboutContext';
import BusinessHeader from '../../components/BusinessHeader';
import { createMedia } from '@artsy/fresnel';
import { InView } from 'react-intersection-observer';

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});  

function DeskopContainer({children}: {children: ReactNode}){
    return (
        <Media greaterThan='mobile'>
            <InView>
                <Segment
                    inverted
                    textAlign='center'
                    style={{padding: '0.5em 0em'}}
                    vertical
                >
                    <BusinessHeader/>
                </Segment>
            </InView>
            {children}
        </Media>
    );
}

function MobileContainer({children}: {children: ReactNode}){
    const [sideBarOpened, setSideBarOpened] = useState(false);
    const {currentBusiness} = useBusinessContext();
    return (
        <Media at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={() => setSideBarOpened(false)}
                    vertical
                    visible={sideBarOpened}
                >
                    <BusinessHeader mobile/>
                </Sidebar>
                <Sidebar.Pusher>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ padding: '0.5em 0em' }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item onClick={() => setSideBarOpened(true)}>
                                    <Icon name='sidebar'/>
                                </Menu.Item>
                                <Menu.Item as='a' header href="/" position='right'>
                                    {currentBusiness?.store_name}
                                </Menu.Item>  
                            </Menu>
                        </Container>
                    </Segment>
                    {children}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    )
}


function ResponsiveContainer({children}: {children: ReactNode}){
    return (
        <MediaContextProvider>
            <DeskopContainer>{children}</DeskopContainer>
            <MobileContainer>{children}</MobileContainer>
        </MediaContextProvider>
    )
}

export default function BusinessPage(){
    const {businessid} = useParams();
    const {setCurrentBusiness} = useBusinessContext();
    
    useEffect(() => {
        if (businessid) {
            setCurrentBusiness(businessid)
        }
    }, [])

    const panes = [
        {
            menuItem: 'Posts', 
            render: () => <Tab.Pane><PostsSubPage/></Tab.Pane> 
        },
        {
            menuItem: 'Products / Services', 
            render: () => <Tab.Pane><ProductsSubPage/></Tab.Pane> 
        },
        { 
            menuItem: 'Payments', 
            render: () => <Tab.Pane><BillingSubPage/></Tab.Pane> 
        },
        {
            menuItem: 'Business Profile', 
            render: () => <Tab.Pane><AboutSubPage/></Tab.Pane>
        }
    ]

    return (
        <PostContextProvider>
        <ProductContextProvider>
        <AboutContextProvider>
        <ResponsiveContainer>
            <Segment basic>
                <Tab
                    renderActiveOnly
                    menu={{fluid: true, tabular: true, widths: 4}}
                    panes={panes}
                />
            </Segment>
        </ResponsiveContainer>
        </AboutContextProvider>
        </ProductContextProvider>
        </PostContextProvider>
    )
}