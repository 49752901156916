
import { Container, Dropdown, Menu } from 'semantic-ui-react';
import { useGeneralContext } from '../storage/context/GeneralContext';
import { useBusinessContext } from '../storage/context/BusinessContext';
import { appName } from '../common/constant';


export default function BusinessHeader({mobile}: {mobile?: boolean}) {
    const { authenticated, currentUser } = useGeneralContext();
    const { businessProfile, currentBusiness} = useBusinessContext();

    if (mobile){
        return (
            <>
                <Menu.Item as='a' href='/'>My Businesses</Menu.Item>
                {authenticated && <Menu.Item as='a' href='/login'>Log Out</Menu.Item>}
                {!authenticated && <Menu.Item as='a' href='/login'>Authentication</Menu.Item>}
            </>
        )
    }

    return (
        <Menu inverted style={{borderRadius: 0}} size='large'>
            <Container>
                <Menu.Item as='a' header href="/">
                    {appName}
                </Menu.Item>
                {(currentBusiness && businessProfile) ? 
                <Menu.Item as='a' href={`https://chaguoo.com/company/${businessProfile.url_basename}`}>
                    {currentBusiness.store_name}
                </Menu.Item> : null}
                <Menu.Menu position='right'>
                    <Menu.Item as='a' href='/'>My Businesses</Menu.Item>    
                    {authenticated ? (
                        <Dropdown item placeholder={currentUser?.displayName || "User"}>
                            <Dropdown.Menu style={{backgroundColor: 'white'}}>
                                <Dropdown.Item as='a' href='/login' text="Log Out"/>
                            </Dropdown.Menu>
                        </Dropdown>
                    ) : (
                        <Menu.Item as='a' href='/login'>Authentication</Menu.Item>
                    )}
                </Menu.Menu>
            </Container>
        </Menu>
    );
}