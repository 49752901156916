import { useCallback, useEffect, useRef, useState } from "react";
import {
    Button, Container, Dimmer, Dropdown,
    Form, Icon, Input, InputOnChangeData, List,
    Loader, Message, Modal, Select, Table, TextArea
} from "semantic-ui-react";
import { useBusinessContext } from "../../storage/context/BusinessContext";
import { ExistingProductData } from "../../models/models";
import { infiniteBusinessProductsNextBatch, infiniteBusinessProductsStart } from "../../api/firebase";
import { ProductContextStatus, useProductContext } from "../../storage/context/ProductContext";
import MediaSlider from "../../components/MediaSlider";
import { CurrenciesOptions } from "../../models/supportedCurrencies";
import { IMAGE_MAX_SIZE } from "../../common/file";

interface ProductModificationModalProps {
    onClose: () => void;
    onOpen: () => void;
    visible: boolean;
}

function ProductModificationModal({ onClose, onOpen, visible }: ProductModificationModalProps) {
    const { addFiles, status, imagesUploaded, currentProduct,
        imagesBeingUploaded, removeFiles, setDescription, setPrice, setName,
        updateProduct, uploadProduct, setType, removeProduct, setCurrency, setCategories
    } = useProductContext();
    const [loading, setLoading] = useState(false);
    const [errorMesssage, setErrorMessage] = useState("");

    const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const selectedFiles: File[] = [];
        if (e.target.files) {
            Array.from(e.target.files).forEach((val) => {
                if (val.size > IMAGE_MAX_SIZE) {
                    alert(`File ${val.name} surpasses the maximum size.`)
                } else {
                    selectedFiles.push(val)
                }
            })
            addFiles(selectedFiles);
        }
    }

    const onUpdateProduct = () => {
        setLoading(true)
        updateProduct()
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                setErrorMessage((err as Error).message);
                setLoading(false);
            });
    }

    const onUploadProduct = () => {
        setLoading(true)
        uploadProduct()
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                setErrorMessage((err as Error).message);
                setLoading(false);
            });
    }

    const onDelete = () => {
        setLoading(true);
        removeProduct()
            .then(() => {
                setLoading(false);
                onClose();
            })
            .catch((err) => {
                setLoading(false);
                setErrorMessage((err as Error).message);
            });
    }
    return (
        <Modal
            onClose={onClose}
            onOpen={onOpen}
            open={visible}
        >
            <Modal.Header>
                {status === ProductContextStatus.New ? "Adding New Product" : "Modifying Product : " + currentProduct.id}
            </Modal.Header>
            <Modal.Content image>
                <Dimmer active={loading}>
                    <Loader content='Loading' />
                </Dimmer>
                <Container>
                    {status === ProductContextStatus.New ?
                        <MediaSlider localFiles={imagesBeingUploaded} />
                        :
                        <MediaSlider remoteFiles={imagesUploaded} />
                    }
                </Container>
                <Container>
                    <Modal.Description style={{ marginLeft: 10 }}>
                        <Form>
                            <Form.Field>
                                <label>Name</label>
                                <Input
                                    type="text"
                                    value={currentProduct.product_name}
                                    onChange={(e, d) => setName(e.target.value)}
                                />
                            </Form.Field>
                            {status === ProductContextStatus.New && <Form.Field>
                                <Input
                                    type="file"
                                    accept="image/*, video/*"
                                    multiple
                                    onChange={handleFileSelect}
                                />
                                Maximum file size 32 MB.
                            </Form.Field>}
                            <Form.Field>
                                <label>Description</label>
                                <TextArea
                                    placeholder='Write your description'
                                    onChange={(e, d) => setDescription(e.target.value)}
                                    value={currentProduct.description}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Categories</label>
                                <TextArea
                                    value={currentProduct.categories?.join(",")}
                                    placeholder="Enter categories to which the product belongs to. Separate each category with a comma."
                                    onChange={(e, d) => setCategories(e.target.value.split(",").map((val) => val.charAt(0).toUpperCase() + val.slice(1)))}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Select
                                    placeholder="Type"
                                    defaultValue={currentProduct.type}
                                    onChange={(e, d) => { setType(d.value?.toString() || "product") }}
                                    options={[
                                        { key: 'product', value: 'product', text: 'Product' },
                                        { key: 'service', value: 'service', text: 'Service' }
                                    ]}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Input
                                    label={
                                        <Dropdown
                                            defaultValue={currentProduct.price.currency}
                                            options={CurrenciesOptions}
                                            onChange={(e, d) => { setCurrency(d.value?.toString() || CurrenciesOptions[0].value) }}
                                        />}
                                    labelPosition='right'
                                    value={currentProduct.price.amount}
                                    type="number"
                                    onChange={(e, d) => { setPrice(e.target.value) }}
                                />
                            </Form.Field>
                        </Form>
                        {status === ProductContextStatus.New &&
                            <List divided verticalAlign="middle">
                                {imagesBeingUploaded.map((file, index) => (
                                    <List.Item key={index}>
                                        <List.Content floated="right">
                                            <Button
                                                content="Delete"
                                                color="red"
                                                onClick={() => removeFiles([file])}
                                            />
                                        </List.Content>
                                        {file.name}
                                    </List.Item>
                                ))}
                            </List>}
                        {errorMesssage &&
                            <Message negative>
                                <Message.Header>There was some errors with your submission</Message.Header>
                                <p>{errorMesssage}</p>
                            </Message>}
                    </Modal.Description>
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" color='black' onClick={onClose} />
                {status === ProductContextStatus.Updating &&
                    <Button
                        content="Delete"
                        color="red"
                        icon="trash"
                        labelPosition="left"
                        onClick={onDelete}
                    />}
                <Button
                    content={status === ProductContextStatus.New ? "Upload" : "Update"}
                    labelPosition='left'
                    icon='checkmark'
                    onClick={status === ProductContextStatus.New ? onUploadProduct : onUpdateProduct}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default function ProductsSubPage() {

    const mounted = useRef(false);
    const { currentBusiness } = useBusinessContext();
    const { setProduct, clearState, setStatus } = useProductContext();
    const [data, setData] = useState<ExistingProductData[]>([]);
    const [lastObject, setLastObject] = useState<any>(null);
    const [productModifModalOpen, setproductModifModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    const fetchDataInitialData = useCallback(async (signal: AbortSignal | null = null) => {
        if (!mounted.current || signal?.aborted) return;
        setLoading(true);
        if (currentBusiness) {
            if (!mounted.current || signal?.aborted) return;
            setData([]);
            const [newIncomingData, startFromObject] = await infiniteBusinessProductsStart(currentBusiness.store_id);
            if (newIncomingData.length > 0) {
                if (!mounted.current || signal?.aborted) return;
                setData((prevData) => [...prevData, ...newIncomingData]);
                setLastObject(startFromObject);
            }
        }
        if (!mounted.current || signal?.aborted) return;
        setLoading(false);
    }, [currentBusiness]);

    useEffect(() => {
        const controller = new AbortController();
        fetchDataInitialData(controller.signal);
        return () => {
            controller.abort();
        }
    }, [fetchDataInitialData]);


    const fetchDataNextData = useCallback(async (signal: AbortSignal | null = null) => {
        if (!mounted.current || signal?.aborted) return;
        setLoading(() => true);
        if (currentBusiness) {
            const [newIncomingData, startFromObject] = await infiniteBusinessProductsNextBatch(currentBusiness.store_id, lastObject);
            if (newIncomingData.length > 0) {
                if (!mounted.current || signal?.aborted) return;
                setData((prevData) => [...prevData, ...newIncomingData]);
                setLastObject(startFromObject);
            }
        }
        if (!mounted.current || signal?.aborted) return;
        setLoading(() => false);
    }, [currentBusiness, lastObject]);

    const onAddNewProduct = () => {
        setproductModifModalOpen(true);
        setStatus(ProductContextStatus.New);
    }

    const onUpdatingPost = (product: ExistingProductData) => {
        setproductModifModalOpen(true);
        setStatus(ProductContextStatus.Updating);
        setProduct(product);
    }

    return (
        <Container fluid>
            <ProductModificationModal
                visible={productModifModalOpen}
                onClose={() => { setproductModifModalOpen(false); clearState(); }}
                onOpen={() => setproductModifModalOpen(true)}
            />
            <Container>
                <Container style={{ marginTop: 10, marginBottom: 10 }}>
                    <h2>Products / Services</h2>
                    <p>Below is a table with all the business products / services.</p>
                </Container>
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Media Content</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Price</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map((product) => {
                            const formatter = new Intl.NumberFormat('en-US', { style: "currency", currency: product.price.currency });
                            return (
                                <Table.Row
                                    key={product.id}
                                    onClick={() => onUpdatingPost(product)}
                                >
                                    <Table.Cell>{product.id}</Table.Cell>
                                    <Table.Cell>{product.media?.length || 0}</Table.Cell>
                                    <Table.Cell>{product.product_name}</Table.Cell>
                                    <Table.Cell>{product.type}</Table.Cell>
                                    <Table.Cell>{formatter.format(product.price.amount)}</Table.Cell>
                                </Table.Row>
                            )
                        }
                        )}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5'>
                                <Button
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    secondary
                                    size='small'
                                    active={!loading}
                                    onClick={() => fetchDataInitialData()}
                                >
                                    <Icon name='refresh' />Reload
                                </Button>
                                <Button 
                                    size='small' 
                                    onClick={() => fetchDataNextData()} 
                                    active={!loading}
                                    >Load More
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Button
                    icon
                    labelPosition='left'
                    primary
                    size='small'
                    onClick={onAddNewProduct}
                    active={!loading}
                >
                    <Icon name='plus' />Add New Product/Service
                </Button>
            </Container>
        </Container>
    )
}