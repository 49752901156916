import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useBusinessContext } from "./BusinessContext";
import { ExistingBusinessProfileData } from "../../models/models";
import { updateBusinessProfileInfo, uploadFileToStorage } from "../../api/firebase";
import * as Sentry from "@sentry/react";

export enum AboutContextState {
    Updating,
    Saved
}

interface AboutContextType {
    addProfilePicture: (file: File) => void;
    uploadedProfilePicture: File | undefined;
    currentObject: ExistingBusinessProfileData | null;
    discardChanges: () => void;
    saveChanges: () => Promise<void>;
    setAbout: (txt: string) => void;
    setBusinessName: (txt: string) => void;
    setShortDescription: (txt: string) => void;
    status: AboutContextState;
    loading: boolean;
}

const AboutContext = createContext<AboutContextType>({
    addProfilePicture: (file: File) => { },
    uploadedProfilePicture: undefined,
    currentObject: null,
    discardChanges: () => { },
    saveChanges: async () => { },
    setAbout: (txt: string) => { },
    setBusinessName: (txt: string) => { },
    setShortDescription: (txt: string) => { },
    status: AboutContextState.Saved,
    loading: true,
})

export const useAboutContext = () => useContext(AboutContext);

interface AboutContextProps {
    children?: ReactNode;
}

export default function AboutContextProvider({ children }: AboutContextProps) {
    const { businessProfile } = useBusinessContext();
    const [loading, setLoading] = useState(true);
    const [fileUploaded, setFileUploaded] = useState<File | undefined>();
    const [currentObject, setCurrentObject] = useState<ExistingBusinessProfileData | null>(null);
    const [status, setStatus] = useState<AboutContextState>(AboutContextState.Saved);

    useEffect(() => {
        setLoading(true);
        if (status !== AboutContextState.Updating) {
            setCurrentObject(businessProfile);
        }
        setLoading(false);
    }, [businessProfile, status]);


    const validityChecks = (profile: ExistingBusinessProfileData) => {
        console.log(profile);
        if (!profile.name) {
            throw new Error("The business name can't be empty");
        }
        if (!profile.store_id) {
            throw new Error("The business store_id is not present.");
        }
        if (!profile.small_description) {
            throw new Error("The business short description should be provided.");
        }
    }

    const onSave = async () => {
        if (status === AboutContextState.Saved || !currentObject) {
            return;
        }
        setLoading(true);
        try {
            const toUpload = { ...currentObject };
            validityChecks(toUpload);
            let newlyUploadedFile;
            if (fileUploaded) {
                newlyUploadedFile = await uploadFileToStorage(fileUploaded, `businesses/${toUpload.store_id}/profileImage`);
            }
            if (newlyUploadedFile) {
                toUpload.image = newlyUploadedFile;
            }
            await updateBusinessProfileInfo(toUpload);
            setStatus((prev) => AboutContextState.Saved);
        }
        catch (error) {
            Sentry.captureException(error);
        } finally {
            setLoading(false);
        }
    }

    const onDiscard = () => {
        if (status === AboutContextState.Saved) {
            return;
        }
        setLoading(true);
        setCurrentObject(businessProfile);
        setStatus(AboutContextState.Saved);
        setLoading(false);
    }

    const onFieldChange = (field: 'about' | 'small_description' | 'name', value: string) => {
        if (currentObject) {
            if (status === AboutContextState.Saved) {
                setStatus((prev) => AboutContextState.Updating);
            }
            setCurrentObject((prev) => {
                if (prev) {
                    const newObject = { ...prev };
                    newObject[field] = value;
                    return newObject;
                }
                return prev;
            });
        }
    }

    const onFileUpload = (file: File) => {
        if (status === AboutContextState.Saved) {
            setStatus((prev) => AboutContextState.Updating);
        }
        setFileUploaded((prev) => file);
    }

    const value = {
        addProfilePicture: onFileUpload,
        uploadedProfilePicture: fileUploaded,
        currentObject: currentObject,
        discardChanges: onDiscard,
        saveChanges: onSave,
        setAbout: (txt: string) => { onFieldChange("about", txt) },
        setBusinessName: (txt: string) => { onFieldChange("name", txt) },
        setShortDescription: (txt: string) => { onFieldChange("small_description", txt) },
        status: status,
        loading: loading
    }

    return (
        <AboutContext.Provider value={value}>
            {children}
        </AboutContext.Provider>
    )
}