import { Button, Container, Header, Icon, Message, Segment, Tab } from "semantic-ui-react";
import { useState } from "react";
import { removeFirebasePrefix } from "../common/format";
import { ResponsiveContainer } from "../components/DefaultResponsiveContainer";
import 'react-international-phone/style.css';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useGeneralContext } from "../storage/context/GeneralContext";
import { PhoneFormLogin } from "../components/PhoneFormLogin";
import { EmailFormLogin } from "../components/EmailAuthForm";
import { loginWithAppleFirebase, loginWithGoogleFirebase, logout } from "../api/authentication";

function LoginSelectorTab({ onceLoginFinished }: { onceLoginFinished: () => void }) {
    const panes = [
        {
            menuItem: 'Email',
            render: () => (
                <Tab.Pane style={{ backgroundColor: 'transparent' }}>
                    <EmailFormLogin
                        onceLoginFinished={onceLoginFinished}
                    />
                </Tab.Pane>
            )
        },
    ]

    return (
        <Tab
            style={{ backgroundColor: 'transparent' }}
            renderActiveOnly
            menu={{ fluid: true, tabular: true, widths: 2 }}
            panes={panes}
        />
    )
}

function ErrorFromUrl() {
    const queryParameters = new URLSearchParams(window.location.search);
    const cause = queryParameters.get("cause");

    if (cause === 'authrequired') {
        return (
            <Container style={{ marginTop: 10 }}>
                <Message icon>
                    <Icon name='info' size={'tiny'} />
                    <Message.Content style={{ textAlign: 'left' }}>
                        <Message.Header>Authentication required</Message.Header>
                        <p>You need to be authenticated to access this page</p>
                    </Message.Content>
                </Message>
            </Container>
        )
    } else {
        return null;
    }

}

export function LoginPage() {

    const navigate = useNavigate();
    const location = useLocation();

    const { authenticated } = useGeneralContext();
    const [error, setError] = useState<string>('');

    const handleLoginWithGoogle = () => {
        setError((prev) => '');
        loginWithGoogleFirebase()
            .then((userCredential) => resumeNavigation())
            .catch((error) => {
                setError((prev) => removeFirebasePrefix(error.code));
            });
    }

    const handleLoginWithApple = () => {
        setError((prev) => '');
        loginWithAppleFirebase()
            .then((userCredential) => resumeNavigation())
            .catch((error) => {
                setError((prev) => removeFirebasePrefix(error.code));
            });
    }

    const resumeNavigation = () => {
        if (location.state && location.state.from && location.state.from.pathname !== '/login') {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    }

    const onSignUpCliked = () => {
        return <Navigate to={"/login"} state={{ from: window.location.pathname }} />
    }

    const handleLogout = () => {
        logout();
    }

    if (error) {
        return (
            <Message negative>
                <Message.Header>We're sorry ! Something wrong refresh the page and retry.</Message.Header>
                <p>{error}</p>
            </Message>
        )
    }

    return (
        <ResponsiveContainer>
            <ErrorFromUrl />
            <Header as='h3' style={{ fontSize: '2em', backgroundColor: 'transparent' }}>
                {authenticated ? 'You are logged in' : 'Login'}
            </Header>
            {!authenticated && <LoginSelectorTab onceLoginFinished={resumeNavigation} />}
            <Segment basic style={{ backgroundColor: 'transparent' }}>
                <Container className="mt-4">
                    {!authenticated ?
                        <>
                            <Container style={{ marginTop: 10, backgroundColor: 'transparent' }}>
                                <Button
                                    onClick={handleLoginWithGoogle}
                                    style={{ width: 200, height: 50 }}
                                    color="google plus"
                                >
                                    <Icon name="google" /> Login with Google
                                </Button>
                            </Container>
                            <Container style={{ marginTop: 10, backgroundColor: 'transparent' }}>
                                <Button
                                    onClick={handleLoginWithApple}
                                    style={{ width: 200, height: 50 }}
                                    color="black"
                                >
                                    <Icon name="apple" /> Login with Apple
                                </Button>
                            </Container>
                        </> : null}
                    {authenticated ?
                        <Container style={{ marginTop: 10, backgroundColor: 'transparent' }}>
                            <Button onClick={handleLogout} style={{ width: 200, height: 50 }} color="red">Logout</Button>
                        </Container> : null}
                </Container>
                {!authenticated ?
                    <Container style={{ marginTop: 20 }}>
                        <Link to="/signup" state={location.state}>Create an account</Link>
                    </Container> : null}
            </Segment>
        </ResponsiveContainer>
    )
}